<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <div class="sc-item">
          <label class="sci-title">轨迹组名称</label>
          <el-input v-model="searchData.groupName" clearable="" placeholder="模糊查询"></el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">状态</label>
          <!-- multiple -->
          <el-select v-model="searchData.statusList" multiple filterable clearable="" placeholder="请选择">
            <el-option v-for="item in trackStatus"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </div>
        <div class="sc-item">
          <label class="sci-title">运单号</label>
          <el-input v-model="searchData.waybillNumber" clearable="" placeholder="运单号"></el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">快递公司</label>
          <el-select v-model="searchData.expressCompanyCode" filterable clearable="" placeholder="请选择">
            <el-option v-for="item in deliveryCompanyData"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            ></el-option>
          </el-select>
        </div>
        <div class="sc-item">
          <label class="sci-title">快递单号</label>
          <el-input v-model="searchData.expressNum" clearable="" placeholder="快递单号"></el-input>
        </div>
        <div class="sc-item">
          <label class="sci-title">提单号</label>
          <el-input v-model="searchData.cnumber" clearable="" placeholder="模糊搜索"></el-input>
        </div>
        <div class="sc-item">
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">查询</el-button>
          <el-button @click="handleReset" icon="el-icon-refresh-left">重置</el-button>
          <el-button type="success" @click="handleAdd" icon="el-icon-plus">新建轨迹组</el-button>
          <el-button type="warning" @click="handleExportTrackGroupToExcel" icon="el-icon-download">导出当前查询</el-button>
        </div>
      </div>
      <div class="table-container">
        <el-table 
        :data="tableData" 
        border="" 
        stripe
        :default-expand-all="true"
        header-cell-class-name="table-header">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-steps :active="props.row.eventActive" align-center finish-status="success" process-status="success">
                <el-step  v-for="item in props.row.eventList" :key="item.id" :title="item.description">
                  <template slot="description">{{ item.eventTime }}</template>
                </el-step>
              </el-steps>
            </template>
          </el-table-column>
          <el-table-column type="index" align="center" label="序号"></el-table-column>
          <el-table-column prop="groupName" label="轨迹组名称"></el-table-column>
          <el-table-column prop="expressCompanyName" label="快递公司"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ scope.row.status | getStatusName }}
            </template>
          </el-table-column>
          <el-table-column prop="cnumber" label="提单号"></el-table-column>
          <el-table-column label="绑定运单数量">
            <template slot-scope="scope">
              <template v-if="scope.row.waybillQty > 0">
                <el-link type="primary" @click="handleDetail(scope.row,'binding')">{{ scope.row.waybillQty }}</el-link>
              </template>
              <template v-else>{{ scope.row.waybillQty }}</template>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="轨迹组有效开始时间"></el-table-column>
          <el-table-column prop="endTime" label="轨迹组结束时间"></el-table-column>
          <el-table-column prop="" width="120px" align="center" label="">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" size="small">
                  <el-dropdown-item><div v-if="scope.row.status == 1" @click="handleClose(scope.row)">关闭</div></el-dropdown-item>
                  <el-dropdown-item><div @click="handleDetail(scope.row)">详情</div></el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.status == 1 || scope.row.status == 2" ><div @click="handleDetail(scope.row,'addwaybil')">批量添加运单</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
    <track-edit :modelData="editObj" v-if="showTrackEdit" @CLOSETRACKEDIT="handleCloseTrackEidt"></track-edit>
    <track-binding :modelData="editObj" v-if="showTrackBinding" @CLOSETRACKBINDING="handleCloseTrackBinding"></track-binding>
    <track-add-waybil :modelData="editObj" v-if="showTrackAddWaybill" @CLOSETRACKBINDING="handleCloseTrackWaybill"></track-add-waybil>
  </div>
</template>

<script>
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import trackEdit from '../../components/track-edit.vue';
import TrackApi from '@/api/track.js'
import dayjs from 'dayjs';
import TrackBinding from '../../components/track-binding.vue';
import TrackAddWaybil from '../../components/track-add-waybil.vue';
export default {
  components: {trackEdit, TrackBinding, TrackAddWaybil},
  name: '',
  data() {
    return {
      showTrackEdit:false,
      showTrackBinding:false,
      showTrackAddWaybill:false,
      currentPage: 1,
      tableTotal: 0,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      trackStatus:[{key: 0, value: '关闭'}, {key:1, value: '新建'}, {key:2, value: '进行中'}, {key:3, value: '推送结束'}], // 轨迹组状态
      deliveryCompanyData:[], // 快递公司
      searchData:{
        groupName:'', // 轨迹组名称
        statusList:[1,2], // 轨迹组状态
        waybillNumber:'', // 运单号
        customNumber:'', // 自定义编号
        expressCompanyCode:'', // 快递公司
        cnumber:'', // 提单号
        expressNum: '', // 快递单号
        platformNumber: '', // 第三方平台单号
      },
      tableData:[],
      editObj:{
        expressCompanyCode:'', // 快递公司
        groupName:'', // 轨迹组名称
        groupCode:'', // 轨迹组编码
        billNumber:'', // 提单号
        validDay:[], // 轨迹组开始结束时间
        startTime:'', // 轨迹组开始时间
        endTime:'', // 轨迹组结束时间
        inStorageTime:'', // 入库时间
        outStorageTime:'', // 出库时间
        flyTime:'', // 航班起飞时间
        landingTime:'', // 降落时间
        carStart:'', // 车辆出发
        carArrive:'', // 车辆到达
        tihuoTime:'', // 提货时间
        qingguangTime:'', // 清关开始
      }, // 编辑对象
    }
  },
  filters: {
    getStatusName(status) {
      switch(status) {
        case 0:
          return '关闭'
          break
        case 1:
          return '新建'
          break
        case 2:
          return '进行中'
          break
        case 3:
          return '推送结束'
          break
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.load()
  },
  mounted() {},
  destroyed() {},
  methods: {
    load(){
      this.getData()
      this.getCourier()
    },
    // 关闭绑定明细
    handleCloseTrackBinding(refresh = false){
      if (refresh) {
        this.currentPage = 1
        this.getData()
      }
      this.showTrackBinding = false
    },
    // 关闭绑定运单
    handleCloseTrackWaybill(refresh = false){
      if (refresh) {
        this.currentPage = 1
        this.getData()
      }
      this.showTrackAddWaybill = false
    },
    // 获取步骤
    getEventActive(eventList){
      let _active = 0
      let _Data = eventList.sort((a,b) => {
        return (a.eventSeq - b.eventSeq)
      })
      _Data.map((item,index) => {
        if(dayjs().isAfter(dayjs(item.eventTime))){
          _active = index
        }
      })
      return _active
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getData()
    },
    base64ToArrayBuffer(base64) {
      var bloBString = window.atob(base64)
      var bloBLen = bloBString.length
      var bytes = new Uint8Array(bloBLen)
        for (var i = 0; i < bloBLen; i++) {
            var ascii = bloBString.charCodeAt(i)
            bytes[i] = ascii
        }
      return bytes
    },
    // 导出轨迹组列表
    handleExportTrackGroupToExcel(){
      TrackApi.exportTrackGroupToExcel({
        ...this.searchData
      }).then((res) => {
        if (res.state) {
          let fileName = '轨迹组'
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(
              new Blob([this.base64ToArrayBuffer(res.data)], { type: 'application/vnd.ms-excel' }),
              fileName + '.xlsx'
            )
          } else {
            let url = window.URL.createObjectURL(
              new Blob([this.base64ToArrayBuffer(res.data)], { type: 'application/vnd.ms-excel' })
            )
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName + '.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link) // 下载完成移除元素
            window.URL.revokeObjectURL(url) // 释放掉blob对象
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 获取快递公司
    getCourier() {
      TrackApi.courier({
        currentPage:1,
        pageSize:999
      }).then(res => {
        if(res.state) {
          this.deliveryCompanyData = res.data.records
        }
      })
    },
    // 查询
    handleSearch(){
      this.currentPage = 1
      this.getData()
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.searchData = {
        groupName:'', // 轨迹组名称
        statusList:[1,2], // 轨迹组状态
        waybillNumber:'', // 运单号
        expressCompanyCode:'', // 快递公司
        cnumber:'', // 提单号
        expressNum: '', // 快递单号
      }
      this.getData()
    },
    // 详情
    handleDetail(row, page='') {
      this.editObj.expressCompanyName = row.expressCompanyName
      this.editObj.waybillQty = row.waybillQty
      this.editObj.status =row.status
      this.editObj.expressCompanyCode = row.expressCompanyCode
      this.editObj.groupName = row.groupName
      this.editObj.groupCode = row.groupCode
      this.editObj.billNumber = row.billNumber
      this.editObj.validDay[0] = row.startTime
      this.editObj.validDay[1] = row.endTime
      this.editObj.startTime = row.startTime
      this.editObj.endTime = row.endTime
      this.editObj.eventActive = row.eventActive
      this.editObj.eventList = row.eventList
      row.eventList.map(item => {
        switch(item.eventSeq){
          case 1:
            this.editObj.inStorageTime = item.eventTime
            break
          case 2:
            this.editObj.outStorageTime = item.eventTime
            break
          case 3:
            this.editObj.flyTime = item.eventTime
            break
          case 4:
            this.editObj.landingTime = item.eventTime
            break
          case 5:
            this.editObj.carStart = item.eventTime
            break
          case 6:
            this.editObj.carArrive = item.eventTime
            break
          case 7:
            this.editObj.tihuoTime = item.eventTime
            break
          case 8:
            this.editObj.qingguangTime = item.eventTime
            break
        }
      })
      if(page == 'addwaybil') {
        this.showTrackAddWaybill = true
      } 
      else if(page == 'binding') {
        this.showTrackBinding = true
      } else {
        this.showTrackEdit = true
      }
    },
    // 新增轨迹组
    handleAdd() {
      this.editObj = {
        expressCompanyCode:'', // 快递公司
        groupName:'', // 轨迹组名称
        groupCode:'', // 轨迹组编码
        billNumber:'', // 提单号
        validDay:[], // 轨迹组开始结束时间
        startTime:'', // 轨迹组开始时间
        endTime:'', // 轨迹组结束时间
        inStorageTime:'', // 入库时间
        outStorageTime:'', // 出库时间
        flyTime:'', // 航班起飞时间
        landingTime:'', // 降落时间
        carStart:'', // 车辆出发
        carArrive:'', // 车辆到达
        tihuoTime:'', // 提货时间
        qingguangTime:'', // 清关开始
      }
      this.showTrackEdit = true
    },
    // 关闭
    handleClose(row) {
      this.$confirm('此操作将永久删除该轨迹组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        TrackApi.close({
          groupCode:row.groupCode
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getData()
        })
      }).catch(() => {      
      });
    },
    // 关闭编辑轨迹组
    handleCloseTrackEidt(refresh = false){
      if (refresh) {
        this.currentPage = 1
        this.getData()
      }
      this.showTrackEdit = false
    },
    getData(){
      this.tableData = []
      TrackApi.page({
        currentPage:this.currentPage,
        pageSize:this.pageSize,
        ...this.searchData
      }).then(res => {
        if (res.state) {
          this.tableData = res.data.records.map(item => {
            item.eventActive = this.getEventActive(item.eventList)
            return item
          })
          this.tableTotal = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table-buts{
  display: flex;
  justify-content: space-evenly;
}
::v-deep{
  .el-step__title {
    font-size: 12px;
    line-height: 20px;
  }
  .el-step__icon{
    width: 18px !important;
    height: 18px !important;
    font-size: 12px !important;
  }
  .el-step.is-horizontal .el-step__line{
    top: 8px !important;
  }
}
</style>
