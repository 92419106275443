<template>
  <div>
    <el-dialog
      :visible="true"
      top="5vh"
      width="1200px"
      :before-close="handleClose"
    >
      <span slot="title">
        <div class="binding-title">
          <span>{{ trackModel.cnumber }}</span>
          <span>{{ trackModel.groupName }}</span>
        </div>
      </span>
      <div class="binding-container">
        <div class="track">
          <span>快递公司：{{ trackModel.expressCompanyName }}</span>
          <span>快递数量：{{ trackModel.waybillQty }}</span>
          <span>状态：{{ trackModel.status | getStatusName }}</span>
        </div>
        <el-steps :active="trackModel.eventActive" align-center finish-status="success" process-status="success">
          <el-step  v-for="item in trackModel.eventList" :key="item.id" :title="item.description">
            <template slot="description">{{ item.eventTime }}</template>
          </el-step>
        </el-steps>
        <el-divider></el-divider>
        <el-table
        :data="tableData"
        border=""
        header-cell-class-name="table-header">
          <el-table-column type="index" align="center" label="序号"></el-table-column>
          <el-table-column prop="expressNum" label="快递单号"></el-table-column>
          <el-table-column prop="batchName" label="运单组"></el-table-column>
          <el-table-column prop="customsStatus" label="清关状态"></el-table-column>
          <el-table-column prop="" label="轨迹状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0" size="mini" type="info" round>未推送</el-tag>
              <el-tag v-else-if="scope.row.status == 1" size="mini"  type="success" round>推送中</el-tag>
              <el-tag v-else-if="scope.row.status == 2" size="mini"  type="success" round>推送完成</el-tag>
              <el-tag v-else-if="scope.row.status == 4" size="mini"  type="success" round>停止推送</el-tag>
              <el-tag v-else size="mini" type="danger" round>推送异常</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="" width="120px" align="center" label="操作">
            <template slot-scope="scope">
              <div class="table-buts">
                <el-link v-if="scope.row.status == 1" @click="handleStop(scope.row)">停止</el-link>
                <el-link v-if="scope.row.status == 0" @click="handleDel(scope.row)">删除</el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TrackApi from '@/api/track.js'
export default {
  components: {},
  name: '',
  props:{
    modelData:{
      type:Object,
      default:() => {
        return {}
      }
    }
  },
  data() {
    return {
      trackModel:{},
      tableData:[]
    }
  },
  filters: {
    getStatusName(status) {
      switch(status) {
        case 0:
          return '关闭'
          break
        case 1:
          return '新建'
          break
        case 2:
          return '进行中'
          break
        case 3:
          return '推送结束'
          break
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.trackModel = JSON.parse(JSON.stringify(this.modelData))
    this.getBinding()
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 关闭
    handleClose() {
      this.$emit('CLOSETRACKBINDING', true)
    },
    // 查询绑定明细
    getBinding(){
      TrackApi.bindingDetail({groupCode:this.trackModel.groupCode}).then(res => {
        if(res.state) {
          this.tableData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 停止推送
    handleStop(row) {
      this.$confirm('此操作将永久停止运单轨迹推送,且停止的运单无法绑定其它轨迹组，是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleStopOrDel(row.id,'stop')
        }).catch(() => {});
    },
    // 删除推送
    handleDel(row) {
      this.$confirm('此操作将删除运单轨迹推送，删除的运单可以重新绑定轨迹组, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleStopOrDel(row.id)
        }).catch(() => {});
    },
    handleStopOrDel(id,type = 'del'){
      TrackApi.bindingCancel({id:id}).then(res => {
        if(res.state) {
          if(type == 'stop') {
            this.$message.success('已停止推送')
          } else {
            this.$message.success('已删除推送')
          }
          this.getBinding()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.binding-title{
  display: flex;
  justify-content: center;
  &>span:first-of-type{
    position: absolute;
    left: 20px;
  }
  &>span:last-of-type{
    font-weight: 16px;
    font-weight: bold;
  }
}
.binding-container{
  .track{
    display: flex;
    justify-content: space-around;
    background: #f8f8f8;
    padding: 10px;
    margin-bottom: 20px;
  }
}
.table-buts{
  display: flex;
  justify-content: space-evenly;
}
::v-deep{
  .el-dialog__body{
    padding:20px;
  }
  .el-step.is-center .el-step__description{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
