<template>
  <div>
    <el-dialog
      title="轨迹组"
      :visible="true"
      top="5vh"
      width="950px"
      :before-close="handleClose"
    >
    <div class="hoho-form-container">
      <el-form 
        :model="trackModel"
        ref="trackForm"
        :rules="rules"
        :inline="true"
        label-width="120px">
        <el-form-item label="快递公司" prop="expressCompanyCode">
          <el-select
            v-model="trackModel.expressCompanyCode"
            clearable=""
            filterable
            placeholder="选择快递公司"
            @change="handleChangeDelivery"
            :disabled="modelData.groupCode != ''"
          >
            <el-option
              v-for="item in deliveryCompanyData"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="轨迹组名称" prop="groupName">
        <el-input
          v-model="trackModel.groupName"
          maxlength="30"
          placeholder="请输入轨迹组名称"
          :disabled="modelData.groupCode != ''"
        ></el-input>
        </el-form-item>
        <el-form-item label="有效时间" prop="validDay">
          <el-date-picker
            v-model="trackModel.validDay"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd"
            @change="handleChangeBeginEnd"
            :picker-options="beginEndPickerOptions"
            :disabled="modelData.groupCode != ''"
          >
          </el-date-picker>
          <div class="billnumber-msg">过了有效时间轨迹组自动关闭！注意关闭后无法打开</div>
        </el-form-item>
        <el-form-item label="提单号">
          <el-select
            v-model="trackModel.billNumber"
            clearable=""
            filterable
            placeholder="选择提单号"
            :disabled="modelData.groupCode != ''"
            @change="handleChangeBillNumber"
          >
            <el-option
              v-for="item in billNumberData"
              :key="item.code"
              :label="item.cnumber"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <div class="billnumber-msg">选择提单号后，【日本】航班起飞时间将无法修改</div>
        </el-form-item>
        <el-divider></el-divider>
        <div class="track-times">
          <div class="t-time">
            <div class="note">
              <div>提示：</div>
              <div class="message">
                <div>五一 国庆 春节 中秋等过年节假日 请顺延设置。</div>
                <div>请根据实际设置推送轨迹，因推送轨迹造成的各种投诉及赔付等后果，将由推送公司承担。</div>
                <div>有问题请及时联系您的代理商确认后再行设定。</div>
                <div>轨迹推送后将无法取消和修改，请务必正确推送。</div>
              </div>
            </div>
            <div :class="haveCarTiem ? 'have-cartime active' : 'have-cartime'" @click="handleClickCarTime">
              <i :class="haveCarTiem ? 'iconfont icon-chenggong' :'iconfont icon-xuanze1'"></i><div>如果有转关，请勾选设定</div>
            </div>
            <div class="time-note"><span>入库时间必须在 轨迹组的开始和结束时间之间</span><span>日期最早提前3天至当前日期后10天</span></div>
            <el-form-item label="①【日本仓】入库时间" prop="inStorageTime">
              <el-date-picker
                v-model="trackModel.inStorageTime"
                type="datetime"
                placeholder="选择【日本仓】入库时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="getDefaultTime()"
                :picker-options="inStorageTimePickerOptions"
                @change="handleChangeInStorage"
                :disabled="modelData.groupCode != ''"
              >
              </el-date-picker>
            </el-form-item>
            <div class="time-note">出库时间可以设定的时间范围：大于入仓时间10分钟以上</div>
            <el-form-item label="②【日本仓】出库时间" prop="outStorageTime">
              <el-date-picker
                v-model="trackModel.outStorageTime"
                type="datetime"
                placeholder="选择【日本仓】出库时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="modelData.groupCode != ''"
              >
              </el-date-picker>
            </el-form-item>
            <div class="time-note">航班起飞可以设定的时间范围：大于出仓时间8小时以上</div>
            <el-form-item label="③【日本】航班起飞" prop="flyTime">
              <el-date-picker
                v-model="trackModel.flyTime"
                type="datetime"
                :readonly="trackModel.billNumber != '' ? true :false"
                placeholder="选择【日本】航班起飞"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="modelData.groupCode != ''"
              >
              </el-date-picker>
            </el-form-item>
            <div class="time-note">航班降落可以设定的时间范围：大于航班起飞的3小时</div>
            <el-form-item label="④【中国】航班降落" prop="landingTime">
              <el-date-picker
                v-model="trackModel.landingTime"
                type="datetime"
                placeholder="选择【中国】航班降落"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="modelData.groupCode != ''"
              >
              </el-date-picker>
            </el-form-item>
            <div class="cartime" v-if="haveCarTiem">
              <div class="time-note">转关发出：车辆发出时间范围：大于航班落地3小时</div>
              <el-form-item label="④-1【中国】车辆发出" prop="carStart">
                <el-date-picker
                  v-model="trackModel.carStart"
                  type="datetime"
                  placeholder="选择【中国】转关发出：车辆发出"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="modelData.groupCode != ''"
                >
                </el-date-picker>
              </el-form-item>
              <div class="time-note">转关到达：车辆到达时间范围：大于车辆出发3小时</div>
              <el-form-item label="④-2【中国】车辆到达" prop="carArrive">
                <el-date-picker
                  v-model="trackModel.carArrive"
                  type="datetime"
                  placeholder="选择【中国】转关到达：车辆到达"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="modelData.groupCode != ''"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="time-note">
              <span>清关提货可以设定的时间范围，尽量避开周六日及节假日：</span>
              <span>大于航班降落8小时以上</span>
              <span>同时时间必须在9:00--16:00之间</span>
            </div>
            <el-form-item label="⑤【中国】清关提货" prop="tihuoTime">
              <el-date-picker
                v-model="trackModel.tihuoTime"
                type="datetime"
                placeholder="选择【中国】清关提货"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="modelData.groupCode != ''"
              >
              </el-date-picker>
            </el-form-item>
            <div class="time-note">
              <span>清关提货可以设定的时间范围，尽量避开周六日及节假日：</span>
              <span>大于航班降落8小时以上</span>
              <span>同时时间必须在9:00--16:00之间</span>
            </div>
            <el-form-item label="⑥【中国】清关开始" prop="qingguangTime">
              <el-date-picker
                v-model="trackModel.qingguangTime"
                type="datetime"
                placeholder="选择【中国】清关开始"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="modelData.groupCode != ''"
              >
              </el-date-picker>
            </el-form-item>
            <div class="font-bold">后续轨迹，自中国网点揽收后</div>
          </div>
          <div class="t-show">
            <div>百度查询模拟显示</div>
            <div class="delivery-demo-img">
              <div class="delivery-name">{{ deliveryName ? deliveryName : '快递公司' }} ></div>
              <div>为您提供优质的一站式服务</div>
              <div class="ddi-but">
                <el-input readonly="" style="width: 200px;" placeholder="请输入快递单号" disabled></el-input>
                <div class="ddi-b-but">查询</div>
              </div>
            </div>
            <div class="track-note"
            v-if="trackModel.inStorageTime || trackModel.flyTime ">
              <div class="note" v-if="trackModel.inStorageTime">
                <div>{{ trackModel.inStorageTime | dateFormat('YYYY年MM月DD日 A h:mm:ss') }}</div>
                <div>[日本][中通国际中心仓]进行处理：订单已操作人库</div>
              </div>
              <div class="note" v-if="trackModel.outStorageTime">
                <div>{{ trackModel.outStorageTime | dateFormat('YYYY年MM月DD日 A h:mm:ss') }}</div>
                <div>[日本]进行处理：订单已操作出库</div>
              </div>
              <div class="note" v-if="trackModel.flyTime">
                <div>{{ trackModel.flyTime | dateFormat('YYYY年MM月DD日 A h:mm:ss') }}</div>
                <div>[日本]干线航班起飞</div>
              </div>
              <div class="note" v-if="trackModel.landingTime">
                <div>{{ trackModel.landingTime | dateFormat('YYYY年MM月DD日 A h:mm:ss') }}</div>
                <div>[中国]干线航班落地</div>
              </div>
              <div class="note" v-if="trackModel.carStart">
                <div>{{ trackModel.carStart | dateFormat('YYYY年MM月DD日 A h:mm:ss') }}</div>
                <div>[中国]的进行处理：干线陆运发出</div>
              </div>
              <div class="note" v-if="trackModel.carArrive">
                <div>{{ trackModel.carArrive | dateFormat('YYYY年MM月DD日 A h:mm:ss') }}</div>
                <div>[中国][中国]的进行处理：车辆干线到达</div>
              </div>
              <div class="note" v-if="trackModel.tihuoTime">
                <div>{{ trackModel.tihuoTime | dateFormat('YYYY年MM月DD日 A h:mm:ss') }}</div>
                <div>[中国]进行处理：快递准备清关提货</div>
              </div>
              <div class="note" v-if="trackModel.qingguangTime">
                <div>{{ trackModel.qingguangTime | dateFormat('YYYY年MM月DD日 A h:mm:ss') }}</div>
                <div>[中国]进行处理：清关开始</div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
      <span slot="footer" class="dialog-footer">
        <span v-if="!this.modelData.groupCode" style="margin-right: 10px;">确认后有绑定的快递单号则无法做任何修改</span> 
        <el-button v-if="!this.modelData.groupCode" @click="handleClose">取 消</el-button>
        <el-button v-else @click="handleClose">关 闭</el-button>
        <el-button v-if="!this.modelData.groupCode" type="primary" class="submit-but" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { vxRule, rules } from '@/utils/validator'
import { GetToken } from 'utils/auth'
import TrackApi from '@/api/track.js'
import dayjs from 'dayjs';
export default {
  components: {},
  name: '',
  props:{
    modelData:{
      type:Object,
      default:function () {
        return {
          expressCompanyCode:'', // 快递公司
          groupName:'', // 轨迹组名称
          groupCode:'', // 轨迹组编码
          billNumber:'', // 提单号
          validDay:[], // 轨迹组开始结束时间
          startTime:'', // 轨迹组开始时间
          endTime:'', // 轨迹组结束时间
          inStorageTime:'', // 入库时间
          outStorageTime:'', // 出库时间
          flyTime:'', // 航班起飞时间
          landingTime:'', // 降落时间
          carStart:'', // 车辆出发
          carArrive:'', // 车辆到达
          tihuoTime:'', // 提货时间
          qingguangTime:'', // 清关开始
        }
      }
    }
  },
  data() {
    let verifyInStorageTime = (rule, value, callback) => {
      try {
        if (value) {
          if(this.trackModel.flyTime != '') {
            if(dayjs(value).isAfter(dayjs(this.trackModel.flyTime).subtract(8,'h').subtract(10,'m'))){
              callback(new Error('入库时间与航班起飞期间不符'))
            }
          }
        } else {
          callback(new Error('请选择入库时间'))
        }
        callback()
      } catch (error) {}
    }
    let verifyOutStorageTime = (rule, value, callback) => {
      try {
        if (value) {
          if(this.trackModel.inStorageTime == '') {
            callback(new Error('请选择入库时间'))
          } else {
            if(dayjs(value).isBefore(dayjs(this.trackModel.inStorageTime).add(10,'m'))){
              callback(new Error('出库时间与入库时间不符'))
            }
          }
          if(this.trackModel.flyTime) {
            if(dayjs(value).isAfter(dayjs(this.trackModel.flyTime).subtract(8,'h'))){
              callback(new Error('出库时间与航班起飞期间不符'))
            }
          }
        } else {
          callback(new Error('请选择出库时间'))
        }
        callback()
      } catch (error) {}
    }
    let verifyFlyTime = (rule, value, callback) => {
      try {
        if (value) {
          if(this.trackModel.outStorageTime == '') {
            callback(new Error('请选择出库时间'))
          } else {
            if(dayjs(value).isBefore(dayjs(this.trackModel.outStorageTime).add(8,'h'))){
              callback(new Error('航班起飞时间与出库时间不符'))
            }
          }
        } else {
          callback(new Error('请选择航班起飞时间'))
        }
        callback()
      } catch (error) {}
    }
    let verifyLandingTime = (rule, value, callback) => {
      try {
        if (value) {
          if(this.trackModel.flyTime == '') {
            callback(new Error('请选择航班起飞时间'))
          } else {
            if(dayjs(value).isBefore(dayjs(this.trackModel.flyTime).add(3,'h'))){
              callback(new Error('航班降落时间与航班起飞时间不符'))
            }
          }
        } else {
          callback(new Error('请选择航班降落时间'))
        }
        callback()
      } catch (error) {}
    }
    let verifyCarStartTime = (rule, value, callback) => {
      try {
        if (value) {
          if(this.haveCarTiem && this.trackModel.landingTime == '') {
            callback(new Error('请选择航班降落时间'))
          } else {
            if(this.haveCarTiem && dayjs(value).isBefore(dayjs(this.trackModel.landingTime).add(3,'h'))){
              callback(new Error('转关发出：车辆发出时间与航班降落时间不符'))
            }
          }
        } else {
          if(this.haveCarTiem && this.trackModel.carStart == '') {
            callback(new Error('请选择转关发出：车辆发出时间'))
          }
        }
        callback()
      } catch (error) {}
    }
    let verifyCarArriveTime = (rule, value, callback) => {
      try {
        if (value) {
          if(this.haveCarTiem && this.trackModel.carStart == '') {
            callback(new Error('请选择转关发出：车辆发出时间'))
          } else {
            if(this.haveCarTiem && dayjs(value).isBefore(dayjs(this.trackModel.carStart).add(3,'h'))){
              callback(new Error('转关发出：车辆到达时间与车辆发出时间不符'))
            }
          }
        } else {
          if(this.haveCarTiem && this.trackModel.carArrive == '') {
            callback(new Error('请选择转关发出：车辆发出时间'))
          }
        }
        callback()
      } catch (error) {}
    }
    let verifyTiHuoTime = (rule, value, callback) => {
      try {
        if (value) {
          if(this.trackModel.landingTime == '') {
            callback(new Error('请选择航班降落时间'))
          } else {
            if(dayjs(value).isBefore(dayjs(this.trackModel.landingTime).add(8,'h'))){
              callback(new Error('清关提货时间与航班降落时间不符'))
            }
          }
        } else {
          callback(new Error('请选择清关提货时间'))
        }
        callback()
      } catch (error) {}
    }
    let verifyQingGuanTime = (rule, value, callback) => {
      try {
        if (value) {
          if(this.trackModel.tihuoTime == '') {
            callback(new Error('请选择清关提货时间'))
          } else {
            if(dayjs(value).isBefore(dayjs(this.trackModel.landingTime).add(8,'h'))){
              callback(new Error('清关开始时间与航班降落时间不符'))
            }
          }
        } else {
          callback(new Error('请选择清关开始时间'))
        }
        callback()
      } catch (error) {}
    }
    return {
      haveCarTiem:false, // 转关
      beginEndChoiceDate: '', // 轨迹组有效时间变量
      beginEndPickerOptions: {
        onPick:({maxDate, minDate}) => {
          this.beginEndChoiceDate = dayjs(minDate).format('YYYY-MM-DD')
          // if(maxDate) {
          //   console.log('clear begin end choice date')
          //   this.beginEndChoiceDate = ''
          // }
        },
        disabledDate:time => {
          if(this.beginEndChoiceDate != '') {
            if(dayjs().subtract(4,'day').add(20,'day').isBefore(dayjs(this.beginEndChoiceDate))){
              return time.getTime() < dayjs().subtract(4,'day') || 
                time.getTime() > dayjs(this.beginEndChoiceDate).add(20,'day') ||
                time.getTime() < dayjs(this.beginEndChoiceDate).subtract(20,'day')
            } else {
              return time.getTime() < dayjs().subtract(4,'day') || 
              time.getTime() > dayjs(this.beginEndChoiceDate).add(20,'day')
            }
          } else {
            return time.getTime() < dayjs().subtract(4,'day') ||
            time.getTime() > dayjs().add(10,'day')
          }
        },
      },
      // 入库时间
      inStorageTimePickerOptions: {
        disabledDate: time => {
          return time.getTime() < dayjs().subtract(4,'day') ||
          time.getTime() > dayjs().add(10,'day')
        }
      },
      trackModel:{},
      billNumberData:[], // 提单号数据
      deliveryName:'',
      deliveryCompanyData:[], // 快递公司数据
      rules: {
        expressCompanyCode: vxRule(true, null, 'change', '请选择快递公司'),
        groupName: vxRule(true, null, '', '请输入轨迹组'),
        validDay: vxRule(true, null, '', '请选择有效开始结束时间'),
        inStorageTime:[{validator:verifyInStorageTime, required: true, trigger: ['blur','change']}],
        outStorageTime:[{validator:verifyOutStorageTime, required: true, trigger: ['blur','change']}],
        flyTime:[{validator:verifyFlyTime, required: true, trigger: ['blur','change']}],
        landingTime:[{validator:verifyLandingTime, required: true, trigger: ['blur','change']}],
        carStart:[{validator:verifyCarStartTime, required: false, trigger: ['blur','change']}],
        carArrive:[{validator:verifyCarArriveTime, required: false, trigger: ['blur','change']}],
        tihuoTime:[{validator:verifyTiHuoTime, required: true, trigger: ['blur','change']}],
        qingguangTime:[{validator:verifyQingGuanTime, required: true, trigger: ['blur','change']}]
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.load()
  },
  destroyed() {},
  methods: {
    load(){
      this.trackModel = JSON.parse(JSON.stringify(this.modelData));
      if(this.trackModel.carStart && this.trackModel.carArrive) {
        this.haveCarTiem = true
      }
      this.getCourier()
      this.getCustomsbill()
    },
    // 转关勾选设定
    handleClickCarTime(){
      this.haveCarTiem = !this.haveCarTiem
    },
    // 生成随机分钟
    getRandomTimeString(hours) {
    const minutes = Math.floor(Math.random() * 60).toString().padStart(2, '0');
    const seconds = '00' // Math.floor(Math.random() * 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
    },
    // 选择入库时间
    handleChangeInStorage(){
      const ww = require('chinese-workday');
      const isWorkday = ww.isWorkday
      if(this.trackModel.inStorageTime) {
        this.trackModel.outStorageTime = dayjs(this.trackModel.inStorageTime).add(1,'day').format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('08')
        if(this.trackModel.billNumber == '') {
          this.trackModel.flyTime = dayjs(this.trackModel.outStorageTime).add(2,'day').format('YYYY-MM-DD') + ' ' + '22:05:00'
        }
        this.trackModel.landingTime = dayjs(this.trackModel.flyTime).add(1,'day').format('YYYY-MM-DD') + ' ' + '05:37:00'
        if(this.haveCarTiem){
          this.trackModel.carStart = dayjs(this.trackModel.landingTime).add(Math.floor(Math.random()*60)+180,'m').format('YYYY-MM-DD HH:mm:00')
          this.trackModel.carArrive = dayjs(this.trackModel.carStart).add(Math.floor(Math.random()*60)+180,'m').format('YYYY-MM-DD HH:mm:00')
        } else {
          this.trackModel.carStart = ''
          this.trackModel.carArrive = ''
        }
        let _week = 1
        _week = dayjs(this.trackModel.landingTime).day()
        if(_week == 0 || _week == 5 || _week == 6) {
          switch(_week) {
            case 0:
              this.trackModel.tihuoTime = dayjs(this.trackModel.landingTime).add(1,'day').format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('14')
              this.trackModel.qingguangTime = dayjs(this.trackModel.tihuoTime).add(1,'day').format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('11')
              break
            case 5:
              this.trackModel.tihuoTime = dayjs(this.trackModel.landingTime).format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('14')
              this.trackModel.qingguangTime = dayjs(this.trackModel.tihuoTime).add(3,'day').format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('11')
              break
            case 6:
              this.trackModel.tihuoTime = dayjs(this.trackModel.landingTime).add(2,'day').format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('14')
              this.trackModel.qingguangTime = dayjs(this.trackModel.tihuoTime).add(1,'day').format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('11')
              break
          }
        } else {
          this.trackModel.tihuoTime = dayjs(this.trackModel.landingTime).format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('14')
          this.trackModel.qingguangTime = dayjs(this.trackModel.tihuoTime).add(1,'day').format('YYYY-MM-DD') + ' ' + this.getRandomTimeString('11')
        }
      }
    },
    // 选择提单号
    handleChangeBillNumber(){
      if(this.trackModel.billNumber) {
        let billModel = this.billNumberData.find(item => {
          return item.code == this.trackModel.billNumber
        })
        if(billModel && billModel.flighttime) {
          this.trackModel.flyTime = dayjs(billModel.flighttime).format('YYYY-MM-DD') + ' ' + '22:05:00'
        }
      }
    },
    // 选择快递公司
    handleChangeDelivery(){
      if (this.trackModel.expressCompanyCode) {
        let _model = this.deliveryCompanyData.find(item => {
          return item.type == this.trackModel.expressCompanyCode
        })
        if(_model && _model.name) {
          this.deliveryName = _model.name
        }
      }
    },
    // 选择轨迹组有效时间
    handleChangeBeginEnd(){
      this.beginEndChoiceDate = ''
    },
    // 获取默认时间
    getDefaultTime(){
      return dayjs().format('HH:mm:00')
    },
    // 获取快递公司
    getCourier() {
      TrackApi.courier({
        currentPage:1,
        pageSize:999
      }).then(res => {
        if(res.state) {
          this.deliveryCompanyData = res.data.records
        }
      })
    },
    // 获取提单号
    getCustomsbill() {
      TrackApi.customsbill({
        currentPage:1,
        pageSize:999
      }).then(res => {
        if(res.state) {
          this.billNumberData = res.data.records
        }
      })
    },
    // 关闭
    handleClose() {
      this.$emit('CLOSETRACKEDIT')
    },
    // 提交
    handleSubmit() {
      this.$refs.trackForm.validate((valid) => {
        if (valid) {
          this.$confirm('提交后绑定任意运单号将无法修改，确认提交吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
          }).then(() => {
            let _eventList = []
            _eventList.push({
              action:'150',
              description:'订单已操作人库',
              eventSeq:1,
              country:'日本',
              eventTime:this.trackModel.inStorageTime,
            })
            _eventList.push({
              action:'190',
              description:'订单已操作出库',
              eventSeq:2,
              country:'日本',
              eventTime:this.trackModel.outStorageTime,
            })
            _eventList.push({
              action:'220',
              description:'干线航班起飞',
              eventSeq:3,
              country:'日本',
              eventTime:this.trackModel.flyTime,
            })
            _eventList.push({
              action:'230',
              description:'干线航班落地',
              eventSeq:4,
              country:'中国',
              eventTime:this.trackModel.landingTime,
            })
            if(this.haveCarTiem) {
              _eventList.push({
                action:'222',
                description:'干线车辆发出',
                eventSeq:5,
                country:'中国',
                eventTime:this.trackModel.carStart,
              })
              _eventList.push({
                action:'232',
                description:'干线车辆到达',
                eventSeq:6,
                country:'中国',
                eventTime:this.trackModel.carArrive,
              })
            }
            _eventList.push({
              action:'330',
              description:'快递准备清关提货',
              eventSeq:7,
              country:'中国',
              eventTime:this.trackModel.tihuoTime,
            })
            _eventList.push({
              action:'360',
              description:'清关开始',
              eventSeq:8,
              country:'中国',
              eventTime:this.trackModel.qingguangTime,
            })
            TrackApi.add({
              billNumber: this.trackModel.billNumber,
              endTime: this.trackModel.validDay[1] + ' ' + '00:00:00',
              eventList: _eventList,
              expressCompanyCode: this.trackModel.expressCompanyCode,
              groupName:this.trackModel.groupName,
              startTime:this.trackModel.validDay[0] + ' ' + '00:00:00',
            }).then(res => {
              if (res.state) {
                this.$emit('CLOSETRACKEDIT', true)
              }
            })
          }).catch(() => {     
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hoho-form-container{
  .track-times{
    display: flex;
    .t-time{
      flex: 1;
      ::v-deep .el-form-item__label{
        width:170px !important
      }
      ::v-deep input{
        font-weight: bold;
      }
      .el-form-item{
        display: flex;
      }
      .note{
        color: #666;
        display: flex;
        margin-bottom: 20px;
        margin-left: 20px;
        .message{
          flex: 1;
          &>div{
            margin-bottom: 5px;
          }
        }
      }
      .time-note{
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        color: #666;
      }
      .font-bold{
        margin-left: 10px;
        font-weight: bold;
        color: #333;
      }
    }
    .t-show{
      flex: 1;
      width: 400px;
      padding:0 20px;
      text-align: center;
      .delivery-demo-img{
        margin-top: 20px;
        height: 90px;
        background: repeating-linear-gradient(188deg, #ddf0ff, #fff 180px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px;
        justify-content: space-around;
        border-radius: 10px 10px 0 0;
        .delivery-name{
          font-size: 16px;
          font-weight: bold;
        }
        .ddi-but{
          display: flex;
          align-items: center;
          .ddi-b-but{
            background: cornflowerblue;
            color: #fff;
            padding: 5px 15px;
            border-radius: 15px;
            margin-left: 20px;
            width: 80px;
          }
        }
      }
      .track-note{
        margin-top: 20px;
        background: #ebfaeb;
        padding: 40px 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 10px;
        .note{
          text-align: left;
          margin-bottom: 30px;
          color: #333;
        }
      }
    }
  }
  .billnumber-msg{
    font-size: 13px;
  }
}
.submit-but{
  width: 300px;
}
.cartime {
  background: #fff5e0;
  padding: 10px 0;
  border-radius: 10px;
  margin-bottom: 10px;
}
.have-cartime{
  background:  #fff5e0;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin: 0 0 10px 0;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  .iconfont{
    margin-right: 5px;
  }
  .iconfont.icon-chenggong{
    color: #4d1c8a;
  }
}
.have-cartime.active{
  color: #4d1c8a;
}
::v-deep {
  .el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-textarea {
    width: 300px;
  }
  .el-divider--horizontal{
    margin:20px 0px;
  }
  .el-form-item__label{
    color: #333;
    font-weight: bold;
  }
  .el-input.is-disabled .el-input__inner, .el-range-editor.is-disabled,.el-range-editor.is-disabled input{
    background: #fff;
    color: #333;
  }
}
</style>
