import { post, get} from '../plugins/request'
import config from '../config/index';

export default {
  // 提单号
  customsbill(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-customsbill/page`, params)
  },
  // 快递公司
  courier(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-expressnum-pay-courier/page`, params)
  },
  // 新建轨迹组
  add(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/add`, params)
  },
  // 轨迹组绑定运单
  binding(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/binding`, params)
  },
  // 取消轨迹组绑定明细
  bindingCancel(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/binding/cancel`, params)
  },
  // 轨迹组绑定明细
  bindingDetail(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/binding/detail`, params)
  },
  // 关闭轨迹组
  close(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/close`, params)
  },
  // 轨迹组明细
  detail(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/detail`, params)
  },
  // 导出轨迹组列表
  exportTrackGroupToExcel(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/exportTrackGroupToExcel`, params)
     // return service({
    //   url: `${config.APIURL}/inbound/header/exportInboundOrderItemsToExcel`,
    //   dataType: 'json',
    //   data: params,
    //   method: 'post',
    //   responseType: 'blob'
    // })
  },
  // 轨迹组列表
  list(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/list`, params)
  },
  // 分页查询轨迹组列表
  page(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/page`, params)
  },
  // 查询有效单据
  checkNumberValid(params, loading = true) {
    Object.assign(params, { loading })
    return post(`${config.APIURL}/logistics-track-group/checkNumberValid`, params)
  },
}
