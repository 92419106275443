<template>
  <div>
    <el-dialog
      :visible="true"
      top="5vh"
      width="1200px"
      :before-close="handleClose"
    >
      <span slot="title">
        <div class="binding-title">
          <span>{{ trackModel.cnumber }}</span>
          <span>{{ trackModel.groupName }}</span>
        </div>
      </span>
      <div class="binding-container">
        <div class="track">
          <span>快递公司：{{ trackModel.expressCompanyName }}</span>
          <span>快递数量：{{ trackModel.waybillQty }}</span>
          <span>状态：{{ trackModel.status | getStatusName }}</span>
        </div>
        <el-steps :active="trackModel.eventActive" align-center finish-status="success" process-status="success">
          <el-step  v-for="item in trackModel.eventList" :key="item.id" :title="item.description">
            <template slot="description">{{ item.eventTime }}</template>
          </el-step>
        </el-steps>
        <el-divider></el-divider>
        <div class="waybill-container">
          <div class="waybill-box">
            <div class="title">
              <div>① 快递单号输入</div>
              <span>数量：{{ inputData.length ? inputData.length : 0 }}</span>
            </div>
            <el-input
              type="textarea"
               :rows="22"
              @blur="setWaybillNo"
              @input="changeWaybillNo"
              placeholder="*仅支持快递单号输入
                          *支持子代理及客户快递单号
                          *已经绑定轨迹组的无法"
              v-model="searchTxt"
              clearable=""
            >
            </el-input>
            <div class="note">
              <span>*仅支持快递单号输入</span>
              <span>*支持子代理及客户快递单号</span>
              <span>*已经绑定轨迹组的无法</span>
              <span>鼠标焦点离开自动校验</span>
            </div>
          </div>
          <div class="table-box">
            <div class="title">
              <div>② 有效明细</div>
              <span>数量：{{ sourceData.length ? sourceData.length : 0 }}</span>
            </div>
            <el-table
            v-if="sourceData.length"
            :data="tableData"
            style="width: 100%;"
            border=""
            header-cell-class-name="table-header">
              <el-table-column type="index" align="center" label="序号">
                <template slot-scope="scope">
                  {{ calcIndex(scope.$index) }}
                </template>
              </el-table-column>
              <el-table-column prop="expressNum" show-overflow-tooltip="" width="120px" label="单号"></el-table-column>
              <el-table-column prop="agencyName" show-overflow-tooltip="" width="200px" label="代理"></el-table-column>
              <el-table-column prop="supplierName" show-overflow-tooltip="" label="客户"></el-table-column>
              <el-table-column prop="batchName" show-overflow-tooltip="" width="120px" label="运单组"></el-table-column>
            </el-table>
            <el-pagination
              v-if="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
          <div class="verify-box">
            <div class="title">
              <div>无效单号</div>
              <span>
                <span>数量：{{ voidNo.length ? voidNo.length : 0 }}</span>
                <i class="iconfont icon-fuzhi" @click="copyText"></i>
              </span>
            </div>
            <div class="verify-no" v-if="voidNo && voidNo.length">
              <span v-for="(item,index) in voidNo" :key="index">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn-box">
          <el-button type="primary" style="width: 200px;" @click="handleSubmit">提交轨迹组</el-button>
          <div class="note">运单绑定轨迹组后将无法解除，请注意！</div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TrackApi from '@/api/track.js'
export default {
  components: {},
  name: '',
  props:{
    modelData:{
      type:Object,
      default:() => {
        return {}
      }
    }
  },
  data() {
    return {
      currentPage:0,
      pageSize:10,
      total:0,
      inputData:[], // 输入运单号
      searchTxt:'', // 输入的快递单号
      waybillNo:[], // 有效运单号
      voidNo:[], // 无效单号
      trackModel:{},
      tableData:[],
      sourceData:[], // 数据源
    }
  },
  filters: {
    getStatusName(status) {
      switch(status) {
        case 0:
          return '关闭'
          break
        case 1:
          return '新建'
          break
        case 2:
          return '进行中'
          break
        case 3:
          return '推送结束'
          break
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.trackModel = JSON.parse(JSON.stringify(this.modelData))
  },
  mounted() {},
  destroyed() {},
  methods: {
    calcIndex(index) {
      if(this.currentPage<2){
        return index + 1
      } else {
        return (this.currentPage -1) * this.pageSize + (index + 1)
      }
    },
    calcPageSize(_current,_pageSize){
      this.tableData = []
      if(_current<2){
        _current = 0
        this.tableData = this.sourceData.slice(_current,_pageSize)
      } else {
        this.tableData = this.sourceData.slice((_current -1)*_pageSize,_current*_pageSize)
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.calcPageSize(this.currentPage,this.pageSize)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.calcPageSize(this.currentPage,this.pageSize)
    },
    changeWaybillNo() {
      this.searchTxt = this.searchTxt.replace(/ /g, '')
    },
    setWaybillNo() {
      this.sourceData = []
      this.waybillNo = []
      this.voidNo = []
      const _no = this.searchTxt.replace(/\n/g, ',').replace(/\s/g, '')
      const _tno = _no.split(',')
      this.inputData = _tno
        .filter((item) => {
          return item !== ''
        })
      if(this.inputData && this.inputData.length){
        this.searchTxt = this.inputData.join(',')
        TrackApi.checkNumberValid({
          groupCode:this.trackModel.groupCode,
          expressNumList:this.inputData
        }).then(res => {
          if(res.state) {
            this.sourceData = res.data
            this.total = this.sourceData.length
            console.log(this.total,'total')
            this.calcPageSize(this.currentPage,this.pageSize)
            this.inputData.map(item => {
              let _model = res.data.find( F =>{
                return F.expressNum == item
              })
              if(_model && _model.expressNum) {
                this.waybillNo.push(_model.waybillNumber)
              } else {
                this.voidNo.push(item)
              }
            })
            console.log(this.voidNo)
          }
        })
      }
    },
    // 复制
    copyText() {
      const textField = document.createElement('textarea');
      textField.innerText = this.voidNo.join(',');
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      this.$message.success('已复制到剪切板')
    },
    // 关闭
    handleClose() {
      this.$emit('CLOSETRACKBINDING', true)
    },
    handleSubmit(){
      if(this.waybillNo && this.waybillNo.length) {
      this.$confirm('绑定轨迹组后无法解除，确认提交吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
          }).then(() => { 
            TrackApi.binding({
              waybillNumberList:this.waybillNo,
              groupCode:this.trackModel.groupCode
            }).then(res => {
              if(res.state) {
                this.$message.success('绑定成功')
              }
              else {
                this.$message.error(res.message)
              }
            })
          }).catch(() => {     
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.binding-title{
  display: flex;
  justify-content: center;
  &>span:first-of-type{
    position: absolute;
    left: 20px;
  }
  &>span:last-of-type{
    font-weight: 16px;
    font-weight: bold;
  }
}
.binding-container{
  .track{
    display: flex;
    justify-content: space-around;
    background: #f8f8f8;
    padding: 10px;
    margin-bottom: 20px;
  }
}
.table-buts{
  display: flex;
  justify-content: space-evenly;
}
.waybill-container{
  display: flex;
  .title{
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #67488f;
    &>span{
      font-weight: 400;
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
    }
  }
  .waybill-box{
    width:200px;
    .note{
      margin-top:5px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      margin-left: 5px;
      span{
        margin-bottom: 5px;
      }
    }
  }
  .table-box{
    flex: 1;
    margin: 0 40px;
  }
  .verify-box{
    width:200px;
    .verify-no{
      border: 1px solid #ebeef5;
      display: flex;
      flex-direction: column;
      padding: 5px 10px 0;
      border-radius: 4px;
      background: #f9f9f9;
      color: #999;    
      overflow-y: auto;    
      height: 435px;
      span{
        margin-bottom: 5px;
      }
    }
  }
}
.btn-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  .note{
    color:brown;
    margin-top: 5px;
  }
}
::v-deep{
  .el-dialog__body{
    padding:20px;
  }
  .el-step.is-center .el-step__description{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
